import React, { useState, useCallback } from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';
import MenuLink from './MenuLink';
import DeranLogo from '../assets/images/deran-logo.svg';
import DeranLogoWhite from '../assets/images/deran-logo-white.svg';
import PhoneIcon from '../assets/images/icons/phone.js';
import EnvelopeIcon from '../assets/images/icons/envelope.js';
import useEventListener from '../hooks/useEventListener';
import { FaBars, FaCaretDown } from 'react-icons/fa';
import styled from 'styled-components';

const isBrowser = typeof window !== "undefined"

const StyledNav = styled.nav`
  pointer-events: none;
`

export default function Nav({ navigationList }) {
  const [stickied, setStickied] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  const [subMenuDropdownShow, setSubMenuDropdownShow] = useState(navigationList.reduce((acc, n) => {
    if (n.navList.length > 0) {
      acc[n.navLabel] = false
    }
    return acc;
  }, {}))

  const scrolled = isBrowser && window.scrollY <= 26;

  const scrollHandler = useCallback(() => {
    if (isBrowser && window.scrollY <= 26) {
      if (stickied) {
        setStickied(false);
      }
    } else {
      if (!stickied) {
        setStickied(true);
      }
    }
  }, [scrolled, stickied])

  const styles = {
    default: 't-flex t-justify-between t-items-center t-w-full t-py-4 t-absolute t-left-0 t-top-0 t-px-4 sm:t-px-0',
    unstickied: 't-text-purple-1000 t-bg-transparent t-sticky t-h-0',
    stickied: 't-bg-purple-1000 t-text-white t-h-16 t-sticky t-z-10'
  }

  useEventListener('scroll', scrollHandler);

  const toggleMenu = () => {
    setMenuShow(prevState => !prevState);
  }

  const toggleMobileDropdown = (e, key) => {
    e.stopPropagation();
    e.preventDefault();

    setSubMenuDropdownShow(prevState => ({
      ...prevState,
      [key]: !prevState[key]
    }))
  }

  return (
    <StyledNav className="t-text-white t-absolute t-w-full t-h-full t-z-10">
      <section className="t-bg-purple-1000">
        <ul className="t-flex t-container t-mx-auto t-justify-center lg:t-justify-end t-py-4 t-text-xs lg:t-text-base">
          <li className="t-flex t-mr-8 t-text-white t-items-center">
            <EnvelopeIcon className="t-h-4 lg:t-h-auto" />
            <p className="t-font-normal t-ml-3 t-text-sm">DERANACADEMY@GMAIL.COM</p>
          </li>
          <li className="t-flex t-items-center">
            <PhoneIcon className="t-h-4 lg:t-h-auto" />
            <p className="t-font-normal t-ml-3 t-text-sm">+1(647)262 - 2068</p>
          </li>
        </ul>
      </section>
      <section className={clsx('t-transition-bg t-top-0 t-left-0 t-w-screen', {
        [styles.stickied]: stickied,
        [styles.unstickied]: !stickied
      })}
      >
        <div className="t-relative t-container t-mx-auto t-pointer-events-auto">
          <ul className={clsx(styles.default)}>
            <li className="t-flex">
              <Link to="/" className="t-h-max">
                <img src={stickied ? DeranLogoWhite : DeranLogo} alt="Deran Academy Logo" className="t-h-8" />
              </Link>
            </li>
            <li className="t-flex lg:t-hidden">
              <button onClick={toggleMenu} className="t-text-xl">
                <FaBars />
              </button>
            </li>
            <ul className="t-items-center t-hidden lg:t-flex">
              {
                navigationList.map((n, i) => (
                  <MenuLink
                    isLast={navigationList.length === i + 1}
                    key={n.navLabel}
                    to={n.navLink}
                    borderColor={stickied ? 'rgb(147, 197, 253)' : 'rgb(31, 0, 71)'}
                    bgClass={'t-bg-purple-1000'}
                    textColorClass={'t-text-white'}
                    menuDropdowns={n.navList}
                    className={clsx({
                      'hover:t-text-blue-300': stickied
                    })}
                  >
                    {n.navLabel}
                  </MenuLink>
                ))
              }
            </ul>
          </ul>
        </div>
        <div className={`t-fixed t-right-0 t-top-0 t-bg-purple-1000 t-h-screen t-flex t-flex-col t-text-white t-list-none t-z-20 t-opacity-90 t-px-4 t-transition-all t-duration-500 t-transform-gpu t-pointer-events-auto ${menuShow ? 't-translate-x-0' : 't-translate-x-full'}`}>
          <button onClick={toggleMenu} className="t-text-xl t-my-5 t-self-end">
            <FaBars />
          </button>
          {
            navigationList.map(n => (
              [
                <MenuLink key={`${n.navLabel}_m`} to={n.navLink} className="t-my-5 t-mx-8">
                  <span className="t-w-full t-flex t-justify-between">
                    <span>
                      {n.navLabel}
                    </span>
                    {
                      n.navList.length > 0 && (
                        <button onClick={e => toggleMobileDropdown(e, n.navLabel)} className="t-text-white t-pl-4">
                          <FaCaretDown />
                        </button>
                      )
                    }
                  </span>
                </MenuLink>,
                <div
                  className={clsx(
                    't-p-2 t-pl-10 t-w-full', {
                    't-hidden': !subMenuDropdownShow[n.navLabel]
                  }
                  )}
                  key={`${n.navLabel}_subm`}
                >
                  {
                    n.navList.map(subNav => (
                      <MenuLink key={subNav.individualLabel} to={subNav.individualUrl} className="t-text-xs t-p-2 t-flex t-flex-wrap t-w-full">
                        {subNav.individualLabel}
                      </MenuLink>
                    ))
                  }
                </div>
              ]
            ))
          }
        </div>
      </section>
    </StyledNav>
  )
}
