import React from 'react';
import FooterImg from '../assets/images/footer-img.svg';
import DeranLogo from '../assets/images/deran-logo-footer.png';

const locations = [
  {
    title: 'Richmond Hill Campus',
    address: '65B West Beaver Creek Rd,',
    address2: 'Richmond Hill, ON L4B'
  },
  {
    title: 'North York Campus',
    address: '2454 Bayview Ave, North York,',
    address2: 'ON M2L 1A6 CA'
  },
  {
    title: 'Markham Campus',
    address: '1A Raymerville Dr, Markham,',
    address2: 'ON L3P 5J5 CA'
  },
]

export default function Footer() {
  return (
    <footer className="t-bg-purple-1000 t-w-screen">
      <div className="t-container t-flex-wrap t-flex-col sm:t-flex-row t-flex t-mx-auto t-py-10 t-px-4 sm:t-px-0">
        <section className="t-order-1 sm:t-order-none t-flex-1 t-flex t-flex-col t-items-center">
          <img className="t-mb-8 t-h-20 md:t-h-1/3" src={DeranLogo} alt="Deran Logo" />
          <figure className="t-flex t-mb-8">
            <img src={FooterImg} className="t-h-14 md:t-h-auto" alt="IB School" />
          </figure>
          <p className="t-text-white t-leading-8">
            2021 © copyright
          </p>
          <p className="t-text-white t-leading-8">
            All rights reserved.
          </p>
        </section>
        <section className="t-flex-1 t-flex t-flex-col t-text-center t-items-center sm:t-text-left sm:t-items-start">
          <p className="t-text-white t-font-semibold t-text-xl md:t-text-2xl">
            Locations
          </p>
          <div className="t-h-px t-bg-purple-300 t-w-32 t-my-2"></div>
          {
            locations.map(location => (
              <div className="t-flex t-flex-col t-my-2" key={location.title}>
                <p className="t-text-xs md:t-text-base t-text-blue-300 t-leading-6 md:t-leading-8">
                  {location.title}
                </p>
                <p className="t-text-xs md:t-text-base t-text-white t-leading-6 md:t-leading-8">
                  {location.address}
                </p>
                <p className="t-text-xs md:t-text-base t-text-white t-leading-6 md:t-leading-8">
                  {location.address2}
                </p>
              </div>
            ))
          }
        </section>
        <section className="t-flex-1 t-flex t-flex-col t-text-center t-items-center sm:t-text-left sm:t-items-start">
          <p className="t-text-white t-font-semibold t-text-xl md:t-text-2xl">
            Reach Us
          </p>
          <div className="t-h-px t-bg-purple-300 t-w-32 t-my-2" />
          <div className="t-flex t-flex-col t-my-2">
            <p className="t-text-xs md:t-text-base t-leading-6 md:t-leading-8">
              <span className="t-text-blue-300">Telephone: </span>
              <span className="t-text-white">+1(289)597-0109</span>
            </p>
            <p className="t-text-xs md:t-text-base t-leading-6 md:t-leading-8">
              <span className="t-text-blue-300">Email: </span>
              <span className="t-text-white">info@deranacademy.com</span>
            </p>
          </div>
          <div className="t-flex t-flex-col t-my-2">
            <p className="t-text-xs md:t-text-base t-leading-6 md:t-leading-8">
              <span className="t-text-blue-300">Web: </span>
              <span className="t-text-white">deranacademy.com</span>
            </p>
            <p className="t-text-xs md:t-text-base t-leading-6 md:t-leading-8">
              <span className="t-text-blue-300">Online Academy: </span>
              <span className="t-text-white">deranacademy.online</span>
            </p>
            <p className="t-text-xs md:t-text-base t-leading-6 md:t-leading-8">
              <span className="t-text-blue-300">Online School: </span>
              <span className="t-text-white">deranschool.online</span>
            </p>
          </div>
          <p className="t-text-white t-font-semibold t-text-xl md:t-text-2xl t-mt-8">
            Admission
          </p>
          <div className="t-h-px t-bg-purple-300 t-w-32 t-my-2" />
          <p className="t-text-xs md:t-text-base t-leading-6 md:t-leading-8">
            <span className="t-text-blue-300">Apply </span>
            <span className="t-text-white">to Deran Academy</span>
          </p>
        </section>
      </div>
    </footer>
  )
}
