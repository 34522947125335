import React from 'react';
import Typography from '../styles/Typography';
import Footer from './Footer';
import Nav from './Nav';
import SEO from './Seo';
import { graphql, useStaticQuery } from 'gatsby';

export default function Layout({ location, float, children }) {
  const { allSanityNav } = useStaticQuery(graphql`
    query {
      allSanityNav(filter: {active: {eq: true}}) {
        nodes {
          navigationList {
            navLink
            navLabel
            navList {
              individualLabel
              individualUrl
              content {
                fileSlug {
                  current
                }
                content {
                  asset {
                    extension
                    url
                  }
                }
              }
              isPDF
            }
          }
        }
      }
    }
  `);
  const [nav] = allSanityNav.nodes;
  const navigationList = nav?.navigationList || [];
  return (
    <>
      <Typography />
      <SEO location={location} />
      <Nav float={float} navigationList={navigationList} />
      {children}
      <Footer />
    </>
  )
}
