import { createGlobalStyle } from 'styled-components';

import fontNormal from '../assets/fonts/BwModelica-Black.otf';
import fontMedium from '../assets/fonts/BwModelica-Medium.otf';
import fontBold from '../assets/fonts/BwModelica-Bold.otf';

const Typography = createGlobalStyle`
  @font-face {
    font-family: BwModelica;
    font-weight: normal;
    src: url(${fontNormal});
  }
  @font-face {
    font-family: BwModelica;
    font-weight: medium;
    src: url(${fontMedium});
  }
  @font-face {
    font-family: BwModelica;
    font-weight: bold;
    src: url(${fontBold});
  }

  body {
    font-family: BwModelica;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    margin: 0;
  }

  p {
    margin: 0;
  }
`;

export default Typography;
