import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const DropdownItem = styled.div`
  padding: 0.75rem 2rem 0.75rem 1rem;
  border-top: 1px solid #999;
  &:hover {
    color: rgb(147, 197, 253);
  }
`;

const DropdownContainer = styled.div`
  transform: translateY(-15px);
  height: max-content;
  transition: all 500ms ease;
  overflow: hidden;
  opacity: 0;

  position: absolute;
  display: flex;
  ${p => p.isLast ? 'right' : 'left'}: 0;
  flex-direction: column;
  padding: 0;
  width: max-content;
  padding-top: 20px;
  transform-origin: top;
  pointer-events: none;

  &:hover {
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;

  }
`;

const MenuListItem = styled.li`
  &:hover ${DropdownContainer} {
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
  }

  &:hover {
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: -10px;
      left: 0;
      background-color: ${p => p.borderColor};
    }
  }
`

export default function MenuLink({ isLast, children, to, bgClass, borderColor = '', textColorClass, className, menuDropdowns = [] }) {
  return (
    <MenuListItem borderColor={borderColor} className={`t-relative t-uppercase t-mx-3 t-border-b t-border-transparent t-text-sm ${className}`}>
      <Link to={to}>
        {children}
      </Link>
      {
        menuDropdowns.length > 0 && (
          <DropdownContainer isLast={isLast} className={`${textColorClass}`}>
            {
              menuDropdowns.map(({ individualLabel, individualUrl, isPDF, content }) => {
                if (isPDF && content) {
                  const slug = content.fileSlug.current;
                  const extension = content.content.asset.extension;
                  const url = `${content.content.asset.url}?dl=${slug}.${extension}`;
                  return (
                    <DropdownItem key={individualLabel} className={`${bgClass}`}>
                      <a className="t-cursor-pointer" target="_blank" href={url}>
                        {individualLabel}
                      </a>
                    </DropdownItem>
                  )
                }
                return (
                  <DropdownItem key={individualLabel} className={`${bgClass}`}>
                    <Link to={individualUrl}>
                      {individualLabel}
                    </Link>
                  </DropdownItem>
                )
              })
            }
          </DropdownContainer>
        )
      }
    </MenuListItem>
  )
}
